<template>
    <div class="content-fixed-wrapper">
        <div class="fixed-item fixed-tab-pane">
            <el-tabs type="border-card" stretch>
                <el-tab-pane label="最新发布">
                    <div class="list-box article-box">
                        <div
                            class="list-item"
                            v-for="(item, index) in dataList.last"
                            :key="index"
                            @click="onClickDetail(item.id, 1)"
                        >
                            <div class="index">{{ index + 1 }}</div>
                            <div class="title">{{ item.title }}</div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="推荐排行">
                    <div class="list-box web-box">
                        <div
                            class="list-item"
                            v-for="(item, index) in dataList.top10"
                            :key="index"
                            @click="onClickDetail(item.id, 1)"
                        >
                            <div class="index">{{ index + 1 }}</div>
                            <div class="title">{{ item.title }}</div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="点击排行">
                    <div class="list-box click-box">
                        <div
                            class="list-item"
                            v-for="(item, index) in dataList.hot10"
                            :key="index"
                            @click="onClickDetail(item.id, 1)"
                        >
                            <div class="index">{{ index + 1 }}</div>
                            <div class="title">{{ item.title }}</div>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
        <div class="fixed-item fixed-web-information">
            <div class="fixed-item-title">关于我们</div>
            <div class="web-information-content">
                <div class="web-information-item">
                    <i class="el-icon-document"></i>
                    <span class="item-sta">文章总数： {{ webInfo.totalInfo }} 篇</span>
                </div>
                <div class="web-information-item">
                    <i class="el-icon-edit"></i>
                    <span class="item-sta">置顶推荐总数: {{ webInfo.totalTop }} 个</span>
                </div>
                <div class="web-information-item">
                    <i class="el-icon-folder-opened"></i>
                    <span class="item-sta">科技项目总数: {{ webInfo.totalArticle }} 个</span>
                </div>
                <div class="web-information-item">
                    <i class="el-icon-chat-line-square"></i>
                    <span class="item-sta">人才信息总数: {{ webInfo.totalTalent }} 条</span>
                </div>
            </div>
        </div>
        <div class="fixed-item fixed-friendship-link">
            <div class="fixed-item-title">友情连接</div>
            <div class="friendship-link-content">
                <div 
                    class="friendship-link-item"
                    v-for="(item, index) in linkArr"
                    :key="index"
                    @click="onClickLink(item.url)"
                >{{ item.name }}</div>
            </div>
        </div>
    </div>
</template>
<script>
export default{
    name: 'homeContent',
    data(){
        return{
            dataList: {},
            webInfo: {
                "totalInfo": 0,
                "totalTop": 0,
                "totalArticle": 0,
                "totalTalent": 0,
            },
            linkArr: []
        }
    },
    mounted(){
        this.getData();
        this.getStatisticsData();
        this.getLinkData()
    },
    methods: {
        getData(){
            this.myaxios({
                url: '/home/api/data',
                method: 'get'
            }).then(res=>{
                if(res.status == 200){
                    this.dataList = res.data
                }else{
                    this.$message.error(res.msg)
                }
            })
        },
        getStatisticsData(){
            this.myaxios({
                url: '/home/api/statistics',
                method: 'get'
            }).then(res=>{
                if(res.status == 200){
                    this.webInfo = res.data
                }else{
                    this.$message.error(res.msg)
                }
            })
        },
        getLinkData(){
            this.myaxios({
                url: '/home/api/links',
                method: 'get'
            }).then(res=>{
                if(res.status == 200){
                    this.linkArr = res.data
                }else{
                    this.$message.error(res.msg)
                }
            })
        },  
        onClickDetail(id, type){
            // this.$router.push(`/detail?id=${id}&type=${type}`)
            window.open(`${window.location.origin}/#/detail?id=${id}&type=${type}`, "_blank")
        },
        onClickLink(link){
            // 打开一个新窗口，URL是 'https://www.example.com'
            window.open(link, '_blank');
        }
    }
}
</script>
<style lang="less" scoped>
// /deep/ .el-tabs{
//     border-radius: 10px;
// }
// /deep/ .el-tabs__header{
//     border-radius: 10px;
// }
/deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active{
    // border-top-right-radius: 10px;
    // border-top-left-radius: 10px;
    border-top: 3px solid;
}
.content-fixed-wrapper{
    box-sizing: border-box;
    .fixed-item{
        margin-bottom: 20px;
        background-color: #fff;
        // border: 1px solid #999;
        // border-radius: 10px;
        .fixed-item-title{
            color: #333;
            background-color: #f5f5f5;
            border: 1px #ddd solid;
            text-align: left;
            padding: 10px 15px;
        }
    }
    .fixed-tab-pane{
        border-top: none;
        margin-bottom: 20px;
        .list-box{
            .list-item{
                display: flex;
                margin: 10px 0;
                .index{
                    background: #ccc;
                    font-size: 13px;
                    display: inline-block;
                    width: 17px;
                    height: 17px;
                    border-radius: 4px;
                    color: #fff;
                    text-align: center;
                    font-style: normal;
                    line-height: 17px;
                    margin-right: 10px;
                }
                .title{
                    width: 260px;
                    text-align: left;
                    color: #566573;
                    white-space: nowrap;
                    word-wrap: normal;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    font-size: 14px;
                    line-height: 17px;
                }
            }
            .list-item:hover{
                cursor: pointer;
                .title{
                    color: #000!important;
                }
            }
        }
    }
    .fixed-web-information{
        border: 1px #ddd solid;
        .web-information-content{
            padding: 15px;
            .web-information-item{
                text-align: left;
                line-height: 1.8;
                font-size: 14px;
                font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
                color: #333;    
                .item-sta{
                    margin-left: 10px;
                }
            }

        }
    }
    .fixed-friendship-link{
        border: 1px #ddd solid;
        .friendship-link-content{
            padding: 15px;
            .friendship-link-item{
                text-align: left;
                font-size: 14px;
                color: #566573;
            }
            .friendship-link-item:hover{
                cursor: pointer;
                color: #0077dd;
                text-decoration: underline;
            }
        }

    }
}
</style>