<template>
    <div>
      <iframe class="iframe" :src="url" frameborder="0" scrolling="no"></iframe>
    </div>
</template>
<script>
export default{
    name: 'homeAdd',
    data(){
      return{
        url: ''
      }
    },
    created(){
      this.url = window.iframeUrl
    }
}
</script>
<style>
.iframe{
  width: 100%;
  height: 4620.98px;
  overflow-y: scroll; /* 使元素垂直可滚动 */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
.iframe::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
</style>