<template>
    <div class="home-content-wrapper">
        <div class="content-home-search" v-if="currentType == 0 || currentType == 1">
            <div class="home-search-logo">
                <img src="@/assets/img/logo.png" alt="">
            </div>
            <div class="home-search-box">
                <el-input 
                    class="search-input" 
                    v-model="keyword" 
                    @keyup.enter.native="onSearch"
                    placeholder="输入关键词、标题或人名"
                ><el-button slot="append" @click="onSearch">查询</el-button></el-input>
                <!-- <div class="search-btn" @click="onSearch">查询</div> -->
            </div>
        </div>
        <div class="content-wrapper">
            <div class="content-list">
                <router-view></router-view>
            </div>
            <div class="content-fixed">
                <ContentFixed></ContentFixed>
            </div>
        </div>
        
    </div>
</template>
<script>
import ContentFixed from "./contentFixed.vue"
export default{
    name: 'homeContent',
    components: {
        ContentFixed
    },
    
    data(){
        return{
            keyword: '',
            currentType: 1
        }
    },
    created(){
        if(this.$route.query.type){
            this.currentType = this.$route.query.type
        }
    },
    watch: {
      "$route.query.type": function(newValue, oldValue){
        this.currentType = newValue
      },
      "$route.query.keyword": function(newValue, oldValue){
      },
    },
    methods: {
        onSearch(){
            if(this.keyword){
                this.$router.push(`/list?type=0&keyword=${this.keyword}`)
            }else{
                this.$router.push(`/list?type=1`)
            }
        }
    }
}
</script>
<style lang="less" scoped>
/deep/ .el-input__inner{
    height: 60px;
    border-radius: 50px;
    line-height: 60px;
    border-right: transparent;
    padding-left: 35px;
}
/deep/ .el-input-group--append .el-input__inner, .el-input-group__prepend{
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-color: transparent;
}
/deep/ .el-input.is-active .el-input__inner, .el-input__inner:focus{
    border-color: #fff!important;
}
/deep/ .el-input-group__append{
    border-bottom-right-radius: 50%;
    border-top-right-radius: 50%;
    background-color: #fff;
    color: #333 ;
    border-color: transparent;
}
.home-content-wrapper{
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    min-height: calc(100vh - 112px);
    text-align: center;
    padding-top: 20px;
    
    .content-home-search{
        padding: 50px 200px 160px 200px;
        height: 60px;
        .home-search-logo{
            margin-bottom: 20px;
            img{
                height: 70px;
            }
        }
        .home-search-box{
            display: flex;
            .search-input{
                height: 60px;
            }
            .search-btn{
              background: #0077dd;
              text-align: center;
              width: 100px;
              line-height: 40px;
              color: #fff;
              margin-left: 20px;
              height: 40px;
            }
            .search-btn:hover{
              cursor: pointer;
            }
        }
    }
    .content-wrapper{
        display: flex;
        justify-content: space-between;
        .content-list{
            width: 800px;
            box-sizing: border-box;
        }
        .content-fixed{
            width: 320px;
            box-sizing: border-box;
        }
    }
}
</style>