<template>
    <div class="home-header-wrapper">
        <div class="header-logo" @click="onSelectMenuItem(1)">
            <img class="img" src="@/assets/img/logo.png" alt="">
            <div class="title">博创汇科技情报平台</div>
        </div>
        <div class="header-menu">
            <div 
                :class="currentMenuItem == item.id ? 'select-item' : 'menu-item'"
                v-for="(item, index) in menuList"
                :key="index"
                @click="onSelectMenuItem(item.id)"
            >{{ item.name }}</div>
        </div>
    </div>
</template>
<script>
export default{
    name: 'homeHeader',
    data(){
        return{
            menuList: [
                {id: 1, name: '科技咨询'},
                {id: 2, name: '人才专栏'},
                {id: 3, name: '科技项目'},
                {id: 4, name: '宣传推荐'},
                {id: 5, name: '团队介绍'},
            ],
            currentMenuItem: 1
        }
    },
    mounted(){

    },
    methods: {
        onSelectMenuItem(id){
            this.currentMenuItem = id
            if(id == 5){
                this.$emit("onChangeIsListPage", false)
                return
            }
            this.$emit("onChangeIsListPage", true)
            this.$router.push(`/list?type=${id}`)
        },
    }
}
</script>
<style lang="less" scoped>
.home-header-wrapper{
    width: 1140px;
    height: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    padding: 10px auto;
    justify-content: space-between;
    .header-logo{
        box-sizing: border-box;
        display: flex;
        .img{
            margin-top: 5px;
            height: 44px;
            box-sizing: border-box;
        }
        .title{
            line-height: 60px;
            margin-left: 20px;
            font-weight: 600;
            font-size: 24px;
            letter-spacing: 0.05em;
        }
    }
    .header-logo:hover{
        cursor: pointer;
    }
    .header-menu{
        box-sizing: border-box;
        display: flex;
        font-size: 15px;
        
        .menu-item{
            box-sizing: border-box;
            line-height: 56px;
            padding: 0px 12px;
            color: #333;
        }
        .select-item{
            box-sizing: border-box;
            color: #0077dd;
            line-height: 56px;
            padding: 0px 12px;
            border-bottom: 3px solid #0077dd;
        }
        .select-item,.menu-item:hover{
            color: #0077dd;
            cursor: pointer;
        }

    }
}
</style>